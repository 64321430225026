import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Dropdown } from "react-bootstrap";
import { useHistory } from 'react-router-dom';
import logo from "../../assets/images/flapform-logo.png";
import api from "../config/api";

export function Header(props) {
  const history = useHistory();
  const { firstname, lastname, username } = JSON.parse(localStorage.getItem('user') || '{}');
  const { t } = useTranslation();

  const closeMenu = (e) => {
    e.target.closest(".dropdown").classList.remove("show");
    e.target.closest(".dropdown .dropdown-menu").classList.remove("show");
  };

  const isPathActive = (path) => {
    return props.location.pathname.startsWith(path);
  };

  const toggleHeaderMenu = (e) => {
    e.preventDefault();
    document.querySelector("body").classList.toggle("az-header-menu-show");
  };

  const signOut = ()=>{
    localStorage.removeItem('token');
    localStorage.removeItem('user');  
    setTimeout(()=>history.push('/signin'),500);
  }

  const manageSubscription = ()=>{
    const token = localStorage.getItem("token");
    api.get('/billing', {
      headers: { authorization: `bearer ${token}` },
    }).then(({data})=>{
      window.location.href = data.billingUrl;
    })
  }

  return (
    <div>
      <div className="az-header">
        <div className="container">
          <div className="az-header-left">
            <a href="/forms" className="az-logo">
            {logo && <img src={logo} width={60}/> }
            </a>
            <a
              id="azMenuShow"
              onClick={(event) => toggleHeaderMenu(event)}
              className="az-header-menu-icon d-lg-none"
              href="/forms"
            >
              <span></span>
            </a>
          </div>
          <div className="az-header-menu">
            <ul className="nav">
              <li
                className={
                  isPathActive("/dashboard") ? "nav-item active" : "nav-item"
                }
                style={{ display: "none" }}
              >
                <Link to="/dashboard" className="nav-link">
                  <i className="typcn typcn-chart-area-outline"></i> Home
                </Link>
              </li>
              <li
                className={
                  isPathActive("/forms") ? "nav-item active" : "nav-item"
                }
              >
                <Link to="/forms" className="nav-link">
                  <i className="typcn typcn-document"></i> {t("Forms")}
                </Link>
              </li>
              <li className="nav-item">
                <Dropdown
                  className={
                    isPathActive("/general-pages")
                      ? "nav-item active"
                      : "nav-item"
                  }
                  style={{ display: "none" }}
                >
                  <Dropdown.Toggle as={"a"} className="nav-link with-sub">
                    <i className="typcn typcn-document"></i> Pages
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="az-menu-sub">
                    <Link
                      to="/signin"
                      className={
                        isPathActive("/signin") ? "nav-link active" : "nav-link"
                      }
                    >
                      Sign In
                    </Link>
                    <Link
                      to="/signup"
                      className={
                        isPathActive("/signup") ? "nav-link active" : "nav-link"
                      }
                    >
                      Sign Up
                    </Link>
                  </Dropdown.Menu>
                </Dropdown>
              </li>
            </ul>
          </div>
          <div className="az-header-right">
            <Dropdown.Menu>
              <div className="az-dropdown-header mg-b-20 d-sm-none">
                <a
                  href="/forms"
                  onClick={(event) => closeMenu(event)}
                  className="az-header-arrow"
                >
                  <i className="icon ion-md-arrow-back"></i>
                </a>
              </div>
              <h6 className="az-notification-title">Notifications</h6>
              <p className="az-notification-text">
                You have 2 unread notification
              </p>
              <div className="az-notification-list">
                <div className="media new">
                  <div className="az-img-user">
                    <img
                      src={require("../../assets/images/img2.jpg")}
                      alt=""
                    ></img>
                  </div>
                  <div className="media-body">
                    <p>
                      Congratulate <strong>Socrates Itumay</strong> for work
                      anniversaries
                    </p>
                    <span>Mar 15 12:32pm</span>
                  </div>
                </div>
                <div className="media new">
                  <div className="az-img-user online">
                    <img
                      src={require("../../assets/images/img3.jpg")}
                      alt=""
                    ></img>
                  </div>
                  <div className="media-body">
                    <p>
                      <strong>Joyce Chua</strong> just created a new blog post
                    </p>
                    <span>Mar 13 04:16am</span>
                  </div>
                </div>
                <div className="media">
                  <div className="az-img-user">
                    <img
                      src={require("../../assets/images/img4.jpg")}
                      alt=""
                    ></img>
                  </div>
                  <div className="media-body">
                    <p>
                      <strong>Althea Cabardo</strong> just created a new blog
                      post
                    </p>
                    <span>Mar 13 02:56am</span>
                  </div>
                </div>
                <div className="media">
                  <div className="az-img-user">
                    <img
                      src={require("../../assets/images/img5.jpg")}
                      alt=""
                    ></img>
                  </div>
                  <div className="media-body">
                    <p>
                      <strong>Adrian Monino</strong> added new comment on your
                      photo
                    </p>
                    <span>Mar 12 10:40pm</span>
                  </div>
                </div>
              </div>
              <div className="dropdown-footer">
                <a href="#/">View All Notifications</a>
              </div>
            </Dropdown.Menu>
            <Dropdown className="az-profile-menu">
              <Dropdown.Toggle as={"a"} className="az-img-user">
                <img
                  src={require("../../assets/images/login_icon.png")}
                  alt=""
                ></img>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <div className="az-dropdown-header d-sm-none">
                  <a
                    href="/forms"
                    onClick={(event) => closeMenu(event)}
                    className="az-header-arrow"
                  >
                    <i className="icon ion-md-arrow-back"></i>
                  </a>
                </div>
                <div className="az-header-profile">
                  <h6>{username}</h6>
                  <span>{`${firstname || ''} ${lastname || ''}`}</span>
                </div>
                <p className="dropdown-item" style={{cursor: 'pointer'}} onClick={manageSubscription}>
                  {t("ManageSubscription")}
                </p>
                <Link onClick={signOut} className="dropdown-item">
                  <i className="typcn typcn-power-outline"></i> Sign Out
                </Link>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(Header);
