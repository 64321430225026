import React, { Component, Suspense, lazy } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
const ForgotPassword = lazy(() => import('./general-pages/ForgotPassword'));
const ResetPassword = lazy(() => import('./general-pages/ResetPassword'));

const Dashboard = lazy(() => import('./dashboard/Dashboard'))

const Signin = lazy(() => import('./general-pages/Signin'))
const Signup = lazy(() => import('./general-pages/Signup'))

const Buttons = lazy(() => import('./ui-elements/Buttons'))
const Dropdowns = lazy(() => import('./ui-elements/Dropdowns'))
const Icons = lazy(() => import('./ui-elements/Icons'))

const FormElements = lazy(() => import('./form/FormElements'))

const ChartJs = lazy(() => import('./charts/ChartJs'))

const BasicTable = lazy(() => import('./tables/BasicTable'))

const FormPage = lazy(() => import('./form/FormPage'))

const FormMain = lazy(() => import('./form/FormMain'))

const FormChat = lazy(() => import('./form/FormChat'))

const FormAnswers = lazy(() => import('./form/FormAnswers'))

const FormConfig = lazy(() => import('./form/FormConfig'))

export class AppRoutes extends Component {
  render() {
    return (
      <Suspense fallback=''>
        <Switch>
          <Route exact path="/">
            <Redirect to="/signin"></Redirect>
          </Route>
          <Route exact path="/dashboard" component={ Dashboard } />

          <Route exact path="/forms" component={ FormMain } />
          <Route exact path="/forms/:id" component={ FormPage } />
          <Route exact path="/answers/:id" component={ FormAnswers } />
          <Route exact path="/formconfig/:id" component={ FormConfig } />
          <Route exact path="/chat" component={ FormChat } />

          <Route exact path="/signin" component={ Signin } />
          <Route exact path="/signup" component={ Signup } />
          <Route exact path="/reset" component={ ResetPassword } />
          <Route exact path="/forgot" component={ ForgotPassword } />

          <Route exact path="/ui-elements/buttons" component={ Buttons } />
          <Route exact path="/ui-elements/dropdowns" component={ Dropdowns } />
          <Route exact path="/ui-elements/icons" component={ Icons } />

          <Route exact path="/form/form-elements" component={ FormElements } />

          <Route exact path="/charts/chartjs" component={ ChartJs } />

          <Route exact path="/tables/basic-table" component={ BasicTable } />

        </Switch>
      </Suspense>
    )
  }
}

export default AppRoutes
